
import App from "../../App"






function PageLoad(){



    return (
        <>
        
       <App/>
       </>
    )
}

export default PageLoad;